import React, {useEffect, useState} from "react"
import {Button, Grid} from "@mui/material";
import WaitList from "./components/WaitList";
import MedsList from "./components/MedsList";
import PatientCard from "../../Components/PatientCard";
import API from "../../api/api";
import PrescriptionNotes from "./components/PrescriptionNotes";
import CheckoutModal from "./components/CheckoutModal";
// import {send_code_to_patient} from "./logic/confirmation";

function Pharmacy() {
    const [waitList, setWaitList] = useState([])
    const [patientID, setPatientID] = useState("")
    const [drugID, setDrugID] = useState("")
    const [patientDetails, setPatientDetails] = useState({})
    const [prescNotes, setPrescription] = useState("")
    const [medication, setMedication] = useState([])
    const [modalStatus, setModalStatus] = useState(false)
    let drug_ids = []

    useEffect(() => {
            API.put("pharmacy/medication/get_patients_with_medications/", {
                    "institution": localStorage.getItem("institution_id"),
                }
            ).then(resp => {
                if (resp.status === 200) {
                    setWaitList(resp.data)
                }
            }).catch(e => {
                console.log(e)
            })
        }, []
    )

    // run the useEffect hook when there is a value in patientID
    useEffect(() => {
        if (patientID !== "") {
            API.put("pharmacy/medication/", {
                    "institution": localStorage.getItem("institution_id"),
                    "patient": String(patientID),
                    "staff": localStorage.getItem('user_id')
                }
            ).then(resp => {
                if (resp.status === 200) {
                    setMedication(resp.data)
                }
            }).catch(e => {
                console.log(e)
            })
            }
        }, [patientID]
    )

    for (let i = 0; i < medication.length; i++) {
        drug_ids.push(medication[i].id)
    }
    return (
        <Grid
            container
            rowSpacing={2}
        >
            <Grid item
                  container
                  justifyContent={"space-between"}
            >
                <PatientCard patientInfo={patientDetails}/>
                <WaitList medicationlist={waitList} setPatientID={setPatientID}
                          setPatientDetails={setPatientDetails} notesRefresh={setPrescription}/>
            </Grid>
            <Grid item
                  container
                  justifyContent={"space-between"}
            >
                <MedsList patient={patientID} getNotes={setPrescription} getDrugID={setDrugID} medication={medication}/>
                <PrescriptionNotes notes={prescNotes} changeNotes={setPrescription} patient={patientID} drug={drugID}/>
            </Grid>

            <Grid
                container
                item
                justifyContent={"center"}
            >
                <Button variant={"outlined"}
                        onClick={() => {
                            setModalStatus(true);
                            // send_code_to_patient(patientID).then(r => r);
                        }}
                >
                    Done with Patient
                </Button>
                <CheckoutModal modalStatus={modalStatus}
                               setModalStatus={setModalStatus}
                               dispensedDrugs={drug_ids}
                               patient={patientID}
                               medication={medication}
                               patientInfo={patientDetails}
                />
            </Grid>
        </Grid>
    )
}

export default Pharmacy;
import React, {useState} from "react";
import {Button, Grid, TextField, Typography} from "@mui/material";
import API from "../../../api/api"

function updatePrescriptionNotes(notes: string, patient_id, drug_id) {
    // This function will update the prescription notes/instructions in case the need arises for it.
    let data = {
        "institution": localStorage.getItem("institution_id"),
        "instructions": notes,
        "staff": localStorage.getItem('user_id'),
        "patient":patient_id,
        "drug_id": drug_id
    }
    console.log(data)
    API.patch("pharmacy/medication/", data).then(
        resp => {
            if (resp.status){
                console.log(resp.data)
            }
        }
    )
}

function PrescriptionNotes({notes, patient, changeNotes, drug}){
    // let [prescNotes, setPrescNotes] = useState(notes)
    return (
        <Grid item
              style={{
                  backgroundColor: "white",
                  borderRadius: 15,
                  minWidth: 450,
                  padding: 10
              }}
        >
            <Typography variant={"h6"}>
                Prescription Notes
            </Typography>
            <Typography variant={"caption"}>
                Select a medication from the table on the right to full in the details here
            </Typography>
            <br/>
            <TextField
                id={"prescription"}
                variant={"outlined"}
                multiline={"true"}
                style={{
                    minWidth: 450
                }}
                defaultValue={notes}
                value={notes}
                onChange={e => changeNotes(e.target.value)}
            />
            <br/>
            <Button
                style={{
                    borderRadius: 10,
                    backgroundColor: "#77c0a3",
                    color: "white",
                    marginRight: 5,
                    marginTop: 10
                }}
                onClick={() => {
                    updatePrescriptionNotes(notes, patient, drug)
                }}
            >
                Update Prescription
            </Button>
        </Grid>
    )
}

export default PrescriptionNotes;
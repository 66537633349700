import React, {useState} from "react";
import PatientRegistrationForm from "./components/RegistrationForm";
import VerificationForm from "./components/VerificationForm";
import {Card, CardContent, Grid, Typography} from "@mui/material";
// import {useNavigate} from "react-router-dom";
import API from "../../api/api";
import {useNavigate} from "react-router-dom";




export default function PatientRegistration() {
    const [view, setView] = React.useState("register");
    const navigate = useNavigate()
    const [checked, setChecked] = useState(false);
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [guardianFirstName, setGuardianFirstName] = useState("");
    const [guardianLastName, setGuardianLastName] = useState("");
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [idNo, setIdNo] = useState("");
    const [dob, setDob] = useState("");
    const [sex, setSex] = useState("")
    const [isChildAccount, setIsChildAccount] = useState(false)
    const [patientType, setPatientType] = useState("adult")
    const [photo, setPhoto] = useState(null)
    const [code, setCode] = useState("")



    function verificationView() {
        return <VerificationForm
            setView={setView}
            setCode={setCode}
            handleFormSubmit={handleFormSubmit}
            checked={checked}
            firstName={firstName}
            lastName={lastName}
            guardianFirstName={guardianFirstName}
            guardianLastName={guardianLastName}
            email={email}
            phone={phone}
            idNo={idNo}
            dob={dob}
            sex={sex}
            code={code}
            patientType={patientType}
            isChildAccount={isChildAccount}
        />
    }

    function handleFormSubmit({firstName, lastName, idNo, dob, sex,phone,
                                  email, guardianFirstName, guardianLastName, isChildAccount,
                                  patientType}) {
        let data = {
            "first_name": firstName,
            "last_name": lastName,
            "date_of_birth": dob,
            "sex": sex,
            "id_or_passport": idNo,
            "phone": phone,
            "email": email,
            "is_child_account": isChildAccount,
            "patient_type": patientType,
            "code": code,
        }
        if (isChildAccount) {
            data.guardian_first_name = guardianFirstName
            data.guardian_last_name = guardianLastName
            data["is_child_account"] = true
            data.patient_type = "child"
        }
        console.log(data)
        API.post("/patient/create/", data)
            .then((resp) => {
                console.log(resp);
                if (resp.status === 201) {
                    alert("Some tidings")
                    navigate("/")
                } else if (resp.status === 200) {
                    // navigate("/login")
                    alert("Good tidings!")
                    navigate("/")
                }
            })
            .catch((e) => {
                console.log(e);
            });
    }

    function registerView() {
        return <PatientRegistrationForm
            checked={checked}
            firstName={firstName}
            lastName={lastName}
            guardianFirstName={guardianFirstName}
            guardianLastName={guardianLastName}
            email={email}
            phone={phone}
            idNo={idNo}
            dob={dob}
            sex={sex}
            code={code}
            isChildAccount={isChildAccount}
            patientType={patientType}
            photo={photo}
            setChecked={setChecked}
            setFirstName={setFirstName}
            setLastName={setLastName}
            setGuardianFirstName={setGuardianFirstName}
            setGuardianLastName={setGuardianLastName}
            setEmail={setEmail}
            setPhone={setPhone}
            setIdNo={setIdNo}
            setDob={setDob}
            setSex={setSex}
            setIsChildAccount={setIsChildAccount}
            setPatientType={setPatientType}
            setPhoto={setPhoto}
            setView={setView}
            handeFormSubmit={handleFormSubmit}
        />
    }

    return (
        <div>
            <Grid container
                  direction="row"
                  justifyContent="space-between"
                  style={{
                      maxWidth: "96%",
                      paddingLeft: "10px",
                      marginLeft: "20px",
                      borderRadius: 5,
                      paddingBottom: 10,
                  }}>
                <Grid item
                      onClick={() => setView("register")}
                >
                    <Card sx={{display: "flex"}} style={{
                        minWidth: 450,
                        padding: 10,
                    }}>
                        <img src="/images/icon/pages/AddPatient/account_info.svg" alt="Account info"/>
                        <CardContent>
                            <Typography>
                                Account Information
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item
                      onClick={() => {
                          setView("verify")
                      }}
                >
                    <Card sx={{display: "flex"}} style={{
                        minWidth: 450,
                        padding: 10,
                    }}>
                        <img src="/images/icon/pages/AddPatient/verify.svg" alt="Account info"/>
                        <CardContent>
                            <Typography>
                                Verify
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>

            {view === "register" ? registerView() : verificationView()}

        </div>
    );
}

import React, {useEffect, useState} from "react"
import {
    Button,
    Card,
    CardContent,
    CardMedia,
    Grid,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography
} from "@mui/material";
import API from "../../api/api";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import Triage from "./Triage";
import TriageDataTable from "./components/TriageDataTable";

export default function Checkin() {
    const [confirmation, setConfirmation] = useState(false);
    const [appointment, setAppointment] = useState([]);
    const [patient, setPatient] = useState({});
    const [triages, setTriages] = useState([]);


    useEffect(() => {
        API.get("appointments/", {
            params: {
                institution_id: localStorage.getItem("institution_id"),
            }
        })
            .then(resp => {
                setAppointment(resp.data["results"]);
                let currentPatient = resp.data["results"]
                setPatient(currentPatient[0])
            })
    }, [])

    useEffect(() => {
        API.get("triage/", {
            params: {
                institution_id: localStorage.getItem("institution_id"),
            }
        })
            .then((resp) => {
                setTriages(resp.data);
            }).catch(err => {
            console.log(err)
        })
    }, [])

    function selectNextPatient(patient_id) {
        // This function will help choose the current patient in-case there is a mismatch
        let currentPatients = appointment

        for (let i = 0; i < currentPatients['length']; i++) {
            if (currentPatients[i].patient === patient_id) {
                setPatient(currentPatients[i]);
                break;
            }
        }
    }

    function selectTriagePatient(patient_id) {
        // This function will help choose the current patient in-case there is a mismatch
        let triagePatients = triages
        console.log(patient_id)

        for (let i = 0; i < triagePatients['length']; i++) {
            if (triagePatients[i].patient_id === patient_id) {
                console.log(triagePatients[i])
                // transform created_at to appointment_time for dynamic card display
                triagePatients[i]['appointment_time'] = triagePatients[i]['created_at']
                triagePatients[i]['patient'] = triagePatients[i]['patient_id']

                setPatient(triagePatients[i]);
                break;
            }
        }
    }

    // console.log(patient)

    let confirmPatient = (
        <Grid container
              direction={"row"}
              // justifyContent={"space-between"}
              spacing={2}
              style={{
                  maxWidth: "96%",
                  paddingLeft: "10px",
                  marginLeft: "20px",
              }}
        >
            <Grid
                item
                container
                justifyContent={"space-between"}
            >


            <Grid item
                  style={{
                      justifyContent: "center",
                      alignItems: "center",
                  }}
            >
                <Card style={{
                    borderRadius: 10,
                    padding: 5,
                    justifyContent: "center",
                    alignItems: "center",
                }}>
                    <CardMedia>
                        <img src={"/images/user/1.jpg"} alt={"user Img"}/>
                    </CardMedia>
                    <CardContent>
                        <Typography variant={"h6"}>
                            Name: {patient ? patient.patient_first_name : ""}
                            <span>{patient ? patient.patient_last_name : ""} </span>
                            <br/>
                            Phone: {patient ? patient.patient_phone : ""}
                            <br/>
                            Time: {patient ? new Date(patient.appointment_time).toLocaleTimeString([], {
                            hour: '2-digit', minute: '2-digit', hour12: true
                        }) : ""}
                            <br/>
                            Doctor: {patient ? patient.doctor_first_name : ""} {patient ? patient.doctor_last_name : ""}
                        </Typography>

                        <Button
                            style={{
                                borderRadius: 20,
                                backgroundColor: "#77c0a3",
                                color: "white",
                                marginRight: 5
                            }}
                            endIcon={<CheckCircleIcon fontSize={"large"}/>}
                            onClick={() => {
                                setConfirmation(true);
                            }}
                        >
                            Confirm Patient
                        </Button>
                        <Button
                            style={{
                                borderRadius: 20,
                                backgroundColor: "#c51212",
                                color: "white",
                                marginLeft: 5
                            }}
                            endIcon={<CancelIcon/>}
                            onClick={() => {
                                console.log("Wrong patient.")
                            }}
                        >
                            Wrong Patient
                        </Button>
                    </CardContent>
                </Card>
            </Grid>
            <Grid item>
                <h4>Appointment List</h4>
                <TableContainer component={Paper}>
                    <Table sx={{minWidth: 600}} size="small" aria-label="Today's appointments">
                        <TableHead>
                            <TableRow>
                                <TableCell>Patient</TableCell>
                                <TableCell>Time</TableCell>
                                <TableCell>Issue</TableCell>

                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {appointment.length > 0 ? appointment.map(({
                                                                           appointment_time,
                                                                           id,
                                                                           patient,
                                                                           patient_first_name,
                                                                           patient_last_name,
                                                                           reason
                                                                       }) => (
                                <TableRow
                                    key={id}
                                    sx={{'&:last-child td, &:last-child th': {border: 0}}}
                                    id={id}
                                    hover
                                    onClick={() => {
                                        selectNextPatient(patient)
                                    }}
                                >

                                    <TableCell scope={"row"}>
                                        {patient_first_name} {patient_last_name}
                                    </TableCell>
                                    <TableCell>{new Date(appointment_time).toLocaleTimeString([], {
                                        hour: '2-digit', minute: '2-digit', hour12: true
                                    })}</TableCell>
                                    <TableCell>{reason}</TableCell>
                                </TableRow>
                            )) : <TableCell>
                                <Typography variant={"h6"}>
                                    No data to show
                                </Typography>
                            </TableCell>}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid>
            </Grid>
            <Grid
                item
            >
                <h4>Walk-in Triage List</h4>
                <TriageDataTable appointment={triages} selectNextPatient={selectTriagePatient}/>
            </Grid>
        </Grid>
    )


    if (confirmation === false) {
        return (
            {...confirmPatient}
        )

    }
    return (
        <Triage patient={patient}/>
    )
}
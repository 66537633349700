import {Button, Checkbox, FormControl, FormGroup, Grid, Radio, RadioGroup, TextField, Typography} from "@mui/material";
import FormControlLabel from "@mui/material/FormControlLabel";
import React from "react";
import {Controller, useForm} from "react-hook-form";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ClearIcon from '@mui/icons-material/Clear';
import {useNavigate} from "react-router-dom";
import API from "../../../api/api"

export default function PatientRegistrationForm({
                                                    firstName,
                                                    lastName,
                                                    idNo,
                                                    code,
                                                    dob,
                                                    sex,
                                                    phone,
                                                    email,
                                                    guardianFirstName,
                                                    guardianLastName,
                                                    isChildAccount,
                                                    patientType,
                                                    // handeFormSubmit,
                                                    checked,
                                                    setChecked,
                                                    setPatientType,
                                                    setDob,
                                                    setEmail,
                                                    setFirstName,
                                                    setGuardianFirstName,
                                                    setGuardianLastName,
                                                    setLastName,
                                                    setPhone,
                                                    setSex,
                                                    setIsChildAccount,
                                                    setIdNo,
                                                    setPhoto,
                                                    setView
                                                    // photo
                                                }) {
    const {control} = useForm({});
    const navigate = useNavigate()


    // verify phone number
    // function verifyPhone(phone: string) {
    //     API.post("/api/user/verifyphone/", {"phone": phone})
    //         .then(res => {
    //             console.log(res.data["message"])
    //             alert(res.data["message"])
    //             setView("verify")
    //         })
    //         .catch(err => {
    //             console.log(err)
    //             alert(err.response.data["non_field_errors"])
    //         })
    // }

    function handleFormSubmit() {
        let data = {
            "first_name": firstName,
            "last_name": lastName,
            "date_of_birth": dob,
            "sex": sex,
            "id_or_passport": idNo,
            "phone": phone,
            "email": email,
            "guardian_first_name": guardianFirstName,
            "guardian_last_name": guardianLastName,
            "is_child_account": isChildAccount,
            "patient_type": patientType,
            "institution_id": localStorage.getItem("institution_id"),
            "staff_id": localStorage.getItem("user_id"),
        }
        if (checked === false) {
            delete data["guardian_first_name"]
            delete data["guardian_last_name"]
            guardianFirstName = ""
            guardianLastName = ""
        }
        // console.log(data)
        API.post("patient/create/", data)
            .then((resp) => {
                // console.log(resp.status);
                // alert("Patient created successfully")
                if (resp.status === 201) {
                    navigate("/")
                }
            })
            .catch((e) => {
                console.log(e.response.data)
                alert(e.response.data.message)
            });
    }

    return (
        <form onSubmit={e => e.preventDefault()}>

            <Grid container onSubmit={handleFormSubmit}
                  style={{
                      backgroundColor: "white",
                      maxWidth: "96%",
                      paddingLeft: "10px",
                      marginLeft: "20px",
                      borderRadius: 5,
                      paddingBottom: 10,
                  }}
            >
                <Grid item xs={12}>
                    <FormGroup>
                        <FormControlLabel
                            control={<Checkbox checked={checked} value={patientType} onChange={(event) => {
                                setPatientType("child")
                                setChecked(event.target.checked)
                                setIsChildAccount(!isChildAccount)
                                if (checked === false) {
                                    setGuardianFirstName("")
                                    setGuardianLastName("")
                                }
                            }}
                            />}
                            label="Child Account?"/>
                    </FormGroup>
                </Grid>
                {checked ? (
                    <>
                        <Grid item xs={6}>
                            <FormGroup>
                                <FormControl>
                                    <Controller
                                        name="parent_first_name"
                                        control={control}
                                        defaultValue=""
                                        render={({field}) => (
                                            <TextField
                                                {...field}
                                                label="Parent/Guardian First Name"
                                                variant="outlined"
                                                style={{
                                                    maxWidth: 460,
                                                    paddingBottom: 15,
                                                }}
                                                onChange={(event) => {
                                                    setGuardianFirstName(event.target.value)
                                                }}
                                                value={guardianFirstName}
                                            />
                                        )}
                                    />
                                </FormControl>
                            </FormGroup>
                        </Grid>
                        <Grid item xs={6}>
                            <FormGroup>
                                <FormControl>
                                    <Controller
                                        name="first_name"
                                        control={control}
                                        defaultValue=""
                                        render={({field}) => (
                                            <TextField
                                                {...field}
                                                label="Parent/Guardian Last Name"
                                                variant="outlined"
                                                style={{
                                                    maxWidth: 460,
                                                    paddingBottom: 15,
                                                }}
                                                onChange={(event) => {
                                                    setGuardianLastName(event.target.value)
                                                }}
                                                value={guardianLastName}
                                            />
                                        )}
                                    />
                                </FormControl>
                            </FormGroup>
                        </Grid>
                    </>
                ) : (
                    <></>
                )}
                <Grid item xs={6}>
                    <Controller
                        name="last_name"
                        control={control}
                        rules={{required: true}}
                        render={({
                                     field: {onChange},
                                     fieldState: {error},
                                 }) => (
                            <TextField
                                required
                                id={"first_name"}
                                label={"first name"}
                                type={"text"}
                                variant={"outlined"}
                                style={{
                                    minWidth: 460,
                                    paddingBottom: 15,
                                }}
                                helperText={"Please enter patient's last name"}
                                error={!!error}
                                value={firstName}
                                onChange={e => setFirstName(e.target.value)}
                            />
                        )}
                    />
                </Grid>
                <Grid item xs={6}>
                    <Controller
                        name="last_name"
                        control={control}
                        rules={{required: true}}
                        render={({
                                     field: {onChange},
                                     fieldState: {error},
                                 }) => (
                            <TextField
                                required
                                id={"last_name"}
                                label={"last name"}
                                type={"text"}
                                variant={"outlined"}
                                style={{
                                    minWidth: 460,
                                    paddingBottom: 15,
                                }}
                                helperText={"Please enter patient's last name"}
                                error={!!error}
                                value={lastName}
                                onChange={e => setLastName(e.target.value)}
                            />
                        )}
                    />
                </Grid>
                <Grid item xs={6}>
                    <Controller
                        name="mobile_number"
                        control={control}
                        rules={{required: true}}
                        render={({
                                     field: {onChange},
                                     fieldState: {error},
                                 }) => (
                            <TextField
                                required
                                id={"mobile_number"}
                                label={"Phone: E.g. 254..."}
                                type={"text"}
                                variant={"outlined"}
                                style={{
                                    minWidth: 460,
                                    paddingBottom: 15,
                                }}
                                helperText={"E.g. 254..."}
                                error={!!error}
                                value={phone}
                                onChange={e => setPhone(e.target.value)}
                            />
                        )}
                    />
                </Grid>

                <Grid item xs={6}>
                    <Controller
                        name="id_number"
                        control={control}
                        rules={{required: true}}
                        render={({
                                     field: {onChange},
                                     fieldState: {error},
                                 }) => (
                            <TextField
                                required
                                id={"email"}
                                label={"ID number"}
                                type={"number"}
                                variant={"outlined"}
                                style={{
                                    minWidth: 460,
                                    paddingBottom: 15,
                                }}
                                error={!!error}
                                value={idNo}
                                onChange={e => setIdNo(e.target.value)}
                            />
                        )}
                    />
                </Grid>
                <Grid item xs={6}>
                    <Typography>
                        Sex
                    </Typography>
                    <FormControl>
                        <RadioGroup>
                            <FormControlLabel value="male" control={<Radio onClick={() => setSex("male")}/>}
                                              label="Male"/>
                            <FormControlLabel value="female" control={<Radio onClick={() => setSex("female")}/>}
                                              label="Female"/>
                        </RadioGroup>
                    </FormControl>

                </Grid>
                <Grid item>
                    <Controller
                        control={control}
                        name="dob"
                        rules={{required: true}}
                        render={({
                                     field: {onChange},
                                     fieldState: {error},
                                 }) => (
                            <TextField
                                required
                                label="Date of Birth"
                                type="date"
                                defaultValue="2017-05-24"
                                sx={{width: 250}}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                error={!!error}
                                value={dob}
                                onChange={e => setDob(e.target.value)}
                            />
                        )}/>
                </Grid>
                {/*	Photo upload section*/}
                <Grid item xs={6}>
                    <Typography>
                        Upload Photo(optional)
                    </Typography>
                    <input type={"file"}
                           accept={"application/pdf, image/jpg, image/jpeg,image/png"}
                           onChange={e => setPhoto(e.target.files[0])}
                    />
                </Grid>
                {/*	email field*/}
                <Grid item xs={6}>
                    <Controller
                        name="email"
                        control={control}
                        rules={{required: false}}
                        render={({
                                     field: {onChange},

                                 }) => (
                            <TextField
                                id={"email"}
                                label={"email"}
                                type={"text"}
                                variant={"outlined"}
                                style={{
                                    minWidth: 460,
                                    paddingBottom: 15,
                                }}
                                value={email}
                                onChange={e => setEmail(e.target.value)}
                            />
                        )}
                    />
                </Grid>
                <Button
                    type={"submit"}
                    variant={"outlined"}
                    onClick={() => navigate("/")}

                    endIcon={<ClearIcon fontSize={"large"}/>}
                    style={{
                        borderRadius: 20,
                        backgroundColor: "#d76215",
                        color: "white",
                        marginRight: 5,
                    }}
                >
                    Cancel
                </Button>
                <Button
                    type={"submit"}
                    variant={"outlined"}
                    onClick={() => {
                        handleFormSubmit({
                            firstName, lastName, idNo, dob, sex, phone,
                            email, guardianFirstName, guardianLastName, isChildAccount,
                            patientType
                        });
                        // verifyPhone(phone);
                    }}
                    endIcon={<CheckCircleIcon fontSize={"large"}/>}
                    style={{
                        borderRadius: 20,
                        backgroundColor: "#77c0a3",
                        color: "white",
                        marginRight: 5,
                    }}
                >
                    Register
                </Button>

            </Grid>
        </form>
    );
}

import React from "react";
import MedsList from "./MedsList";
import {Button, Card, Grid} from "@mui/material";
import PatientCard from "../../../Components/PatientCard";
import API from "../../../api/api"


function dispenseDrugs(drugs, patient_id) {
    let data = {
        "patient": patient_id,
        "drugs":drugs,
        "institution": localStorage.getItem("institution_id"),
        "staff": localStorage.getItem('user_id')
    }
    console.log(data)
    API.patch("pharmacy/issue_medication/", data).then(resp => {
        if (resp.status === 200){
            window.location.reload(false)
        } else {
            console.log(resp.data)
        }
    }).catch(error => {
        console.log(error)
    })
}

export default function DispenseDialog({drugs, medicationList, patientInfo, patient}) {
    return (
        <Grid
            container
            rowSpacing={2}
        >
            <Grid item
                  container
                  justifyContent={"space-between"}
                  borderTop={7}
                  borderColor={"white"}
            >
                <Card style={{
                    minWidth: 500,
                    borderRadius: 15,
                    padding: 10
                }}>
                    <MedsList medication={medicationList}/>
                </Card>
                <Grid item>
                    <PatientCard patientInfo={patientInfo}/>
                </Grid>
            </Grid>
            <Grid
                container
                item
                justifyContent={"center"}
            >
                <Button variant={"outlined"}
                        onClick={() =>{
                            dispenseDrugs(drugs, patient)
                        }}
                >
                    Dispense Drugs
                </Button>
            </Grid>
        </Grid>

    )
}
import React, {useState} from "react";
import API from "../../../api/api";
import {Button, Grid, TextField} from "@mui/material";
import {Controller, useForm} from "react-hook-form";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import InstitutionSelect from "./InstitutionSelect";
import Logout from "./CleanUpLogout";

export default function StaffLogin() {
    const [authenticated, setAuthenticated] = useState(
        localStorage.getItem(localStorage.getItem("authenticated") || false)
    );
    const [open, setOpen] = useState(false);
    const [institution, setInstitution] = useState([]);
    const {
        handleSubmit,
        control,
        formState: {errors},
    } = useForm({
        defaultValues: {
            email: "",
        },
    });

    // check to see if user has al
    async function handleFormSubmit(data) {
        await API.post("login/user/", {
            email: data.email,
            password: data.password,
            user_type: "admin",
            institution: localStorage.getItem("institution_id"),
        })
            .then((resp_data) => {
                if (resp_data.status === 200) {
                    setAuthenticated(true);
                    localStorage.setItem("auth_token", resp_data.data["auth_token"]);
                    localStorage.setItem("user_email", resp_data.data["user_email"]);
                    localStorage.setItem("user_id", resp_data.data["id"]);
                    localStorage.setItem("user_type", resp_data.data["user_type"]);
                    localStorage.setItem(
                        "user_first_name",
                        resp_data.data["user_first_name"]
                    );
                    localStorage.setItem(
                        "user_last_name",
                        resp_data.data["user_last_name"]
                    );
                    localStorage.setItem("authenticated", true);


                    if (resp_data.data.staff_type) {
                        localStorage.setItem(
                            "staff_type",
                            resp_data.data.staff_type
                        );
                    } else if (resp_data.data.admin_type) {
                        localStorage.setItem(
                            "admin_type",
                            resp_data.data.admin_type
                        );
                    }


                    // Check if user is admin
                    if (resp_data.data) {
                        // choose hospital
                        chooseHospital(resp_data.data["user_id"]);
                    } else {
                        window.location = '/';
                    }
                }
            })
            .catch((e) => {
                // check if response status is 403
                if (e.response.status === 403) {
                    alert(e.response.data["detail"]);
                    Logout(); // This will log out legitimate users; an indication of possible password compromise.
                }
                alert(e.response.data["non_field_errors"][0])
                console.log(e.response.data["non_field_errors"][0]);
            });
    }

    function chooseHospital(user_id) {
        // check for account access
        API.get("accountaccess/", {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("auth_token")}`,
            },
            params: {
                user_id: user_id,
            },
        })
            .then((resp_data) => {
                if (resp_data.data.results && resp_data.data.count > 0) {
                    // set institution
                    setInstitution(resp_data.data.results);
                    setOpen(true);
                } else if (resp_data.data.results && resp_data.data.count === 0) {
                    // tell the user their account has been suspended
                    console.log("Your account has been suspended. Please contact your administrator.");
                    Logout();
                    alert("Your account has been suspended. Please contact your administrator.");
                    localStorage.clear();
                }
            })
            .catch((e) => {
                console.log(e.response.data);
            });
    }

    return (
        <Grid
            container
            justifyContent={"flex-end"}
            style={{
                backgroundImage: `url(${
                    process.env.PUBLIC_URL + "images/pages/signin-bg.jpg"
                })`,
                height: "100vh",
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
                paddingLeft: 5,
                paddingRight: 50,
            }}
        >
            <InstitutionSelect open={open} setOpen={setOpen} institution={institution}/>
            <Grid
                item
                alignSelf={"center"}
                style={{
                    backgroundColor: "white",
                    borderRadius: 10,
                    padding: 7,
                    maxHeight: 500,
                }}
            >
                <Grid
                    item
                    container
                    direction={"column"}
                    alignItems={"center"}
                    style={{
                        paddingLeft: 5,
                        marginRight: 50,
                    }}
                >
                    <img
                        src={"/images/afyasasa-main-logo.png"}
                        alt={"AfyaSasa Logo"}
                        width={236.05}
                    />
                    <form onSubmit={handleSubmit(() => alert(1))}>
                        <Controller
                            name="email"
                            control={control}
                            rules={{required: "Email is required"}}
                            render={({
                                         field: {onChange, email},
                                         fieldState: {error},
                                     }) => (
                                <TextField
                                    required
                                    id={"email"}
                                    label={"Email"}
                                    type={"email"}
                                    variant={"outlined"}
                                    style={{
                                        minWidth: 470,
                                        paddingBottom: 15,
                                    }}
                                    helperText={error ? error.message : null}
                                    error={!!error}
                                    value={email}
                                    onChange={onChange}
                                />
                            )}
                        />
                        <br/>
                        <Controller
                            name="password"
                            control={control}
                            rules={{required: "Password is required"}}
                            render={({
                                         field: {onChange, password},
                                         fieldState: {error},
                                     }) => (
                                <TextField
                                    required
                                    id={"password"}
                                    label={"Password"}
                                    variant={"outlined"}
                                    type={"password"}
                                    style={{
                                        minWidth: 470,
                                        paddingBottom: 15,
                                    }}
                                    helperText={error ? error.message : null}
                                    value={password}
                                    error={!!error}
                                    onChange={onChange}
                                />
                            )}
                        />

                        <br/>
                        <Button
                            type={"submit"}
                            variant={"outlined"}
                            onClick={handleSubmit(handleFormSubmit)}
                            endIcon={<CheckCircleIcon fontSize={"large"}/>}
                            style={{
                                borderRadius: 20,
                                backgroundColor: "#77c0a3",
                                color: "white",
                                marginRight: 5,
                            }}
                        >
                            Login
                        </Button>
                    </form>
                    <hr/>
                </Grid>
            </Grid>
        </Grid>
    );
}

import React from 'react';
import {Box, Button, Grid, MenuItem, Modal, TextField, Typography} from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

export default function InstitutionSelect({open, institution, setOpen}) {
    return (
        <Modal open={open}
        >
            <Box>

                <Grid
                    container
                    justifyContent={"center"}
                    alignItems={"center"}
                    style={{
                        height: "100vh",
                        backgroundColor: "rgba(0,0,0,0.5)",
                    }}
                >
                    <Grid
                        container
                        direction={"column"}
                        justifyContent={"center"}
                        alignItems={"center"}
                        style={{
                            backgroundColor: "white",
                            padding: 20,
                            borderRadius: 10,
                            width: "50%",
                        }}
                    >
                        <CheckCircleIcon
                            style={{
                                color: "green",
                                fontSize: 100,
                            }}
                        />
                        <Typography variant={"h6"}>Select Institution</Typography>
                        <TextField
                            select
                            type={"text"}
                            style={{width: "100%"}}
                        >
                            {institution.map((option) => (
                                <MenuItem key={option.institution_id}
                                          value={option.institution_name}
                                          onClick={() => {
                                              localStorage.setItem(
                                                  "institution_id",
                                                  option.institution_id
                                              );
                                              localStorage.setItem(
                                                  "institution_name",
                                                  option.institution_name
                                              );
                                          }}
                                >
                                    {option.institution_name}
                                </MenuItem>
                            ))}
                        </TextField>
                        <Button
                            variant={"contained"}
                            color={"primary"}
                            style={{
                                margin: 10,
                            }}
                            onClick={() => {
                                setOpen(false);
                                window.location = "/";
                            }}
                        >
                            Continue
                        </Button>
                    </Grid>
                </Grid>
            </Box>
        </Modal>
    )
}

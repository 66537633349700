import React from 'react'
import {Box, Button, Grid, MenuItem, Modal, TextField, Typography} from "@mui/material";
import Api from "../../api/api";

// Status options
const statusOptions = [
    {value: 'active', label: 'Active'},
    {value: 'disabled', label: 'Disabled'},
]
const staffTypes = [
    {value: 'admin', label: 'Office Admin'},
    {value: 'nurse', label: 'Nurse'},
    {value: 'doctor', label: 'Doctor'},
]


export default function CreateUsers({open, setOpen}) {
    const [email, setEmail] = React.useState('');
    const [firstName, setFirstName] = React.useState('');
    const [lastName, setLastName] = React.useState('');
    const [phone, setPhone] = React.useState('');
    const [staffType, setStaffType] = React.useState('');
    const [status, setStatus] = React.useState('');
    const [password, setPassword] = React.useState('');
    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
        borderRadius: 10,
        width: "1050px",
        height: "500px"
    };

async function CreateStaff() {
    await Api.post('/staff/create/', {
        "first_name": firstName,
        "last_name": lastName,
        "institution_id": localStorage.getItem("institution_id"),
        "email": email,
        "phone": phone,
        "password": password,
        "staff_type": staffType,
        "status": status
    }).then((response) => {
        if (response.status === 200 || response.status === 201) {
            alert("Staff created successfully")
            setOpen(false)
            window.location.reload()

        }
    }).catch((error) => {
        console.log(error)
    })
}


    return (
        <Modal open={open}>
            <Box sx={style}>
                <Grid item>
                    <Typography variant={"h4"}>Create new user</Typography>
                </Grid>
                <Grid item>
                    {/*Row 1*/}
                    <Grid container direction={"row"} spacing={2}>
                        <Grid item xs={6}>
                            <Typography variant={"h6"}>First name</Typography>
                            <TextField type={"text"}
                                       style={{width: "100%"}}
                                       required={true}
                                       onChange={(e) => {
                                           setFirstName(e.target.value)
                                       }}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <Typography variant={"h6"}>Last name</Typography>
                            <TextField type={"text"}
                                       required={true}
                                       style={{width: "100%"}}
                                       onChange={(e) => {
                                           setLastName(e.target.value)
                                       }}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item>
                    {/*Row 2*/}
                    <Grid container direction={"row"} spacing={2}>
                        <Grid item xs={6}>
                            <Typography variant={"h6"}>Email</Typography>
                            <TextField type={"email"}
                                        required={true}
                                       style={{width: "100%"}}
                                       onChange={(e) => {
                                           setEmail(e.target.value)
                                       }}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <Typography variant={"h6"}>Phone number</Typography>
                            <TextField type={"text"}
                                        required={true}
                                       style={{width: "100%"}}
                                       onChange={(e) => {
                                           setPhone(e.target.value)
                                       }}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item>
                    {/*Row 3*/}
                    <Grid container direction={"row"} spacing={2}>
                        <Grid item xs={6}>
                            <Typography variant={"h6"}>Staff Type</Typography>
                            <TextField
                                select
                                type={"text"}
                                       style={{width: "100%"}}>
                                {staffTypes.map((option) => (
                                    <MenuItem key={option.value}
                                              value={option.value}
                                                onClick={() => {
                                                    setStaffType(option.value)
                                                }}
                                    >
                                        {option.label}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </Grid>
                        <Grid item xs={6}>
                            <Typography variant={"h6"}>Status</Typography>
                            <TextField
                                select
                                type={"text"}
                                style={{width: "100%"}}
                            >
                                {statusOptions.map((option) => (
                                    <MenuItem key={option.value}
                                              value={option.value}
                                                onClick={() => {
                                                    setStatus(option.value)
                                                }}
                                    >
                                        {option.label}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item>
                    {/*Row 4*/}
                    <Grid container direction={"row"} spacing={2}>
                        <Grid item xs={12}>
                            <Typography variant={"h6"}>Password</Typography>
                            <TextField type={"text"}
                                       style={{width: "100%"}}
                                       onChange={(e) => {
                                           setPassword(e.target.value)
                                       }}
                            />
                        </Grid>
                    </Grid>
                </Grid>

                <Grid item>
                    {/*Save and cancel buttons*/}
                    <Grid container direction={"row"} spacing={2}
                          justifyItems={"center"}
                          justifyContent={"center"}
                          style={{marginTop: "10px"}}
                    >
                        <Grid item>
                            <Button
                                style={{
                                    backgroundColor: "#77c0a3",
                                    borderRadius: 10,
                                    color: "white",
                                    alignItems: "center"
                                }}
                                onClick={() => {
                                    CreateStaff()
                                }}
                            >Save</Button>
                        </Grid>
                        <Grid item>
                            <Button
                                style={{
                                    backgroundColor: "#3E3E65C2",
                                    borderRadius: 10,
                                    color: "white",
                                    alignItems: "center"
                                }}
                                onClick={() => setOpen(false)}>Close</Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Box>
        </Modal>
    )
}
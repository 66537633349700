import React, {useState} from 'react';
import {
    Box,
    Card,
    CardContent, CardMedia,
    IconButton,
    List,
    ListItem,
    ListItemSecondaryAction,
    ListItemText,
    Modal,
    Tab,
    Tabs,
    Typography
} from '@mui/material';
import {ChevronRight} from "@mui/icons-material";

const HistoryModal = ({history, handleClose, openHist}) => {
    const [selectedTab, setSelectedTab] = useState(0);
    const [selectedConsultation, setSelectedConsultation] = useState(null);
    const [selectedLab, setSelectedLab] = useState(null);


    function handleConsultationClick(consultation) {
        setSelectedConsultation(consultation);
    }

    function handleLabClick(lab) {
        setSelectedLab(lab);
    }

    function handleLabClick(lab) {
        setSelectedLab(lab);
    }

    return (
        <div>

            <Modal
                open={openHist}
                onClose={handleClose}
                aria-labelledby="Patient History"
                aria-describedby="Patient History Details"
                style={{
                    overflow: 'scroll',
                }}
            >
                <Box
                    style={{
                        position: 'absolute',
                        backgroundColor: 'white',
                        transform: 'translate(-50%, -50%)',
                        top: '50%',
                        left: '50%',
                        border: '1px solid #000',
                        width: "1050px",
                        height: "600px",
                        boxShadow: 24,
                        p: 4,
                        borderRadius: 10,
                        padding: 5,
                        overflow: 'auto',
                    }}
                >
                    <Tabs value={selectedTab} onChange={(e, newValue) => setSelectedTab(newValue)}>
                        <Tab label="Consultations"/>
                        <Tab label="Labs"/>
                    </Tabs>
                    {selectedTab === 0 && history.consultations && history.consultations.length > 0 && (
                        <React.Fragment>
                            <List>
                                {history && history["consultations"].length > 0 ? history["consultations"].slice(0, 5).map((consultation) => (
                                    <ListItem onClick={() => handleConsultationClick(consultation)}>
                                        <ListItemText primary={consultation.reason_for_visit}
                                                      secondary={consultation.date}/>
                                        <ListItemSecondaryAction>
                                            <IconButton edge="end">
                                                <ChevronRight/>
                                            </IconButton>
                                        </ListItemSecondaryAction>
                                    </ListItem>
                                )) : null}
                            </List>
                            <Typography variant="h5">Detailed View</Typography>
                            {selectedConsultation && (
                                <Card>
                                    <CardContent>
                                        <Typography variant="h6">Reason for
                                            Visit: {selectedConsultation.reason_for_visit}</Typography>
                                        <Typography
                                            variant="body1">{new Date(selectedConsultation.created_at).toDateString()}</Typography>
                                        <Typography variant="body1">Diagnosis:
                                            {selectedConsultation.diagnosis ? " " + selectedConsultation.diagnosis : " None"}
                                        </Typography>
                                        <Typography variant="body1">Doctor's notes:
                                            {selectedConsultation.diagnosis ? " " + selectedConsultation.doctors_notes : " None"}
                                        </Typography>
                                        {/* Render other details of the selected consultation here */}
                                    </CardContent>
                                </Card>
                            )}

                        </React.Fragment>
                    )}

                    {selectedTab === 1 && history.labs && history.labs.length > 0 && (
                        <React.Fragment>
                            <List>
                                {history["labs"].slice(0, 5).map((lab) => (
                                    <ListItem onClick={() => handleLabClick(lab)}>
                                        <ListItemText primary={lab.test_name} secondary={new Date(lab.created_at).toDateString()}/>
                                        <ListItemSecondaryAction>
                                            <IconButton edge="end">
                                                <ChevronRight/>
                                            </IconButton>
                                        </ListItemSecondaryAction>
                                    </ListItem>
                                ))}
                            </List>
                            <Typography variant="h5">Detailed View</Typography>
                            {selectedLab && (
                                <Card>
                                    <CardContent>
                                        <Typography variant="h6">Test name: {selectedLab.test_name}</Typography>
                                        <Typography
                                            variant="body1">{new Date(selectedLab.created_at).toDateString()}</Typography>
                                        <Typography variant="body1">Test type:
                                            {selectedLab.test_type}
                                        </Typography>
                                        <Typography variant="body1">Results notes:
                                            {selectedLab.results ? " " + selectedLab.results : " None"}
                                        </Typography>
                                        {/* Render other details of the selected consultation here */}
                                        <CardMedia
                                            component="img"
                                            src={process.env.REACT_APP_URL + selectedLab.attachments.substring(5)}
                                            alt="lab image"
                                        />
                                    </CardContent>
                                </Card>
                            )}
                        </React.Fragment>
                    )}
                </Box>
            </Modal>
        </div>
    );
};

export default HistoryModal;

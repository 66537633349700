// noinspection SpellCheckingInspection

import {Card, CardContent, CardMedia, Typography, Grid} from "@mui/material";
import React from "react";

export default function card(props){
    return (
        <Grid item>
            <Card style={{
                alignItems: "center",
                display: "flex",
                borderRadius: 15,
                padding: 10
            }}>
                <CardMedia style={{
                    alignItems: "center",
                    display: "flex",
                    padding: 15
                }}>
                    <img src={process.env.PUBLIC_URL + `/images/icon/pages/Dashboard/${props.imgname}.svg`}
                         alt={`${props.imgalt}`}/>
                </CardMedia>

                <CardContent>
                    <Typography variant={"h5"} align={"right"}>
                        {props.num}
                    </Typography>
                    <Typography variant={"h6"}>
                        {props.imgname}
                    </Typography>
                </CardContent>
            </Card></Grid>
    )
}
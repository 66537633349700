import React, {useEffect, useState} from "react"
import {Alert, Button, FormControl, Grid, InputLabel, MenuItem, Select, Snackbar, Typography} from "@mui/material";
import InputField from "./components/InputField";
import API from "../../api/api";




export default function Triage({patient}) {
    const [systolic_bp, setSystolic] = useState("");
    const [diastolic_bp, setDiastolic] = useState("");
    const [respiratory_rate, setRespiratoryRate] = useState("");
    const [temperature, setTemperature] = useState(36);
    const [rbs, setRbs] = useState("");
    const [oxygen_saturation, setOxygenSaturation] = useState("");
    const [reason_for_visit, setReasonForVisit] = useState("");
    const [open, setOpen] = useState(false);
    const [availableDoctors, setAvailableDoctors] = useState([]);
    const [selectedDoctor, setSelectedDoctor] = useState(null);

    // ensure that logged-in user is admin and or staff_type is nurse
    if (localStorage.getItem('user_type') !== 'staff') {
        window.location.href = '/'
    }


    useEffect(() => {
        API.get('staff/get_allowed_doctors/', {
            params: {
                institution_id: localStorage.getItem("institution_id")
            }
        })
            .then((response) => {
                setAvailableDoctors(response.data);
            });
    }, [])

    const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }
    };

function triage(patient_id: string,
                systolic_bp: string,
                diastolic_bp: string,
                spo2: string,
                rbs: string,
                triage_id?: string,
                doctor_id?: string,
                reason_for_visit?: string,
) {
    let data = {
        "institution_id": localStorage.getItem("institution_id"),
        "patient_id": patient_id,
        "systolic_bp": systolic_bp,
        "diastolic_bp": diastolic_bp,
        "resp_rate": respiratory_rate,
        "spo2": spo2,
        "rbs": rbs,
        "seen": true,
        "id": triage_id,
        // "doctor_id": doctor_id,
        "reason": reason_for_visit
    }

    if (doctor_id) {
        data["doctor_id"] = doctor_id;
    } else {
        data["doctor_id"] = selectedDoctor.id;
    }



    //
    API.patch('triage/', data)
        .then((response) => {
            if (response.status === 201) {
                alert("Triage successful");
                window.location.reload();
            }
        }, (error) => {
            console.log(error);
        });
}
    function setDoctor(doctor_id: string) {
        for (let i = 0; i < availableDoctors.length; i++) {
            if (availableDoctors[i].id === doctor_id) {
                setSelectedDoctor(availableDoctors[i]);
                return;
            }
        }
    }

    return (
        <Grid container
              rowSpacing={2}
              justifyContent={"space-between"}
              style={{
                  backgroundColor: "white",
                  maxWidth: "96%",
                  padding: "10px",
                  marginLeft: "20px",
                  marginTop: "20px"
              }}
        >
            <Grid item>
                <Typography variant={"h3"}>
                    Triage Form
                </Typography>
                <Typography variant={"h5"}>
                    Patient Name: {patient.patient_first_name} {patient.patient_last_name}
                </Typography>
                <Typography variant={"h5"}>
                    Doctor To see: {patient.doctor_first_name || "Not assigned yet"} {patient.doctor_last_name}
                </Typography>
            </Grid>

            <InputField field_name={"Vitals Systolic BP"}
                        value={systolic_bp}
                        onChange={setSystolic}
                        required={true}
                        type={"number"}
                        field_label={"Systolic"}/>

            <InputField field_name={"Diastolic BP"}
                        value={diastolic_bp}
                        onChange={setDiastolic}
                        required={true}
                        type={"number"}
                        field_label={"Diastolic"}/>

            <InputField field_name={"Respiratory Rate"}
                        value={respiratory_rate}
                        onChange={setRespiratoryRate}
                        required={true}
                        field_label={"Resp Rate"}/>
            <InputField field_name={"Temperature"}
                        value={temperature}
                        onChange={setTemperature}
                        required={true}
                        type={"string"}
                        field_label={"Temperature"}/>
            <InputField field_name={"RBS"} value={rbs} required={true} onChange={setRbs} field_label={"RBS"}/>
            <InputField field_name={"SPO2 Sat"}
                        value={oxygen_saturation}
                        required={true}
                        onChange={setOxygenSaturation}
                        field_label={"SPO2"}/>
            <InputField field_name={"Reason for visit"}
                        value={reason_for_visit || patient.reason}
                        onChange={setReasonForVisit}
                        field_label={"Reason"}/>



            <FormControl sx={{m: 1, minWidth: 120}}>
                <InputLabel id="doctor">
                    {selectedDoctor === null ?
                        `${patient.doctor_first_name || "Assign"} ${patient.doctor_last_name || "Doctor"}` :
                        "Assign Doctor"}
                </InputLabel>
                <Select
                    style={{
                        minWidth: 300
                    }}

                    labelId="doctor-label"
                    id="doctor"
                    value={selectedDoctor ? selectedDoctor.id : ''}
                    label={patient.staff ? `${patient.doctor_first_name}` : "Assign Doctor"}
                    disabled={!!patient.staff}
                    required={false}
                >
                    {availableDoctors.map(doctor => (
                        <MenuItem
                            id={doctor.id || patient.staff}
                            value={patient.staff ? patient.staff: doctor.id}
                            onClick={() => {
                                setDoctor(doctor.id)
                            }}
                        >{doctor.first_name} {doctor.last_name}</MenuItem>
                    ))
                    }
                </Select>
            </FormControl>

            <Grid item>
                <Button
                    variant={"contained"}
                    style={{
                        borderRadius: 10,
                        backgroundColor: "#77c0a3",
                        color: "white",
                        marginRight: 5,
                        marginTop: 10
                    }}
                    onClick={() => {
                        triage(
                            patient.patient,
                            systolic_bp, diastolic_bp,
                            oxygen_saturation, rbs,
                            patient.id,
                            patient.staff,
                            reason_for_visit || patient.reason,
                        )
                    }}>
                    Save
                </Button>
                <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
                    <Alert onClose={handleClose} severity="success" sx={{width: '100%'}}>
                        This is a success message!
                    </Alert>
                </Snackbar>
            </Grid>

        </Grid>
    )
}



import React from "react";
import LogRocket from "logrocket";
import {Route, Routes} from "react-router-dom";
import MainLayout from "./Components/MainLayout";
import StaffLogin from "./pages/auth/Login/StaffLogin";
import PatientRegistration from "./pages/auth/PatientRegistration";
import Calendar from "./pages/Calender-Reminders/calendar";
import Dashboard from "./pages/Dashboard/dashboard";
import Consultation from "./pages/Doctor-Appointments/Consultation";
import Laboratory from "./pages/lab/laboratory";
import Pharmacy from "./pages/pharmacy/pharmacy";
import PrivateRoutes from "./utils/PrivateRoutes";
import Checkin from "./pages/checkin/Checkin";
import Users from "./pages/users/Users";
import API from "./api/api";


// const app_id = process.env.LOG_ROCKET_ID
LogRocket.init('3wd7pc/afyasasa');

LogRocket.identify(
    localStorage.getItem("user_id"),
    {
        name: localStorage.getItem("user_first_name") + " " + localStorage.getItem("user_last_name"),
        email: localStorage.getItem("user_email"),
        role: localStorage.getItem("user_type"),
    }
)

// Add event listener for clicks and mouse movements
document.addEventListener("mousemove", () => {
    localStorage.setItem("lastActivity", new Date())
});
document.addEventListener("click", () => {
    localStorage.setItem("lastActivity", new Date())
});

function logout() {
    API.post("logout/user/", {
        "token": localStorage.getItem("auth_token"),
        "email": localStorage.getItem("user_email"),
    })
        .then(() => {
            localStorage.clear();
            window.location.reload();
        })
        .catch((error) => {
            console.log(error);
            // Clear localStorage anyway
            localStorage.clear();
            window.location.reload();
        });
}

let timeInterval = setInterval(() => {
    let lastActivity = localStorage.getItem('lastActivity')
    let diffMs = Math.abs(new Date(lastActivity) - new Date()); // milliseconds between now & last activity
    let seconds = Math.floor((diffMs / 1000));
    let minute = Math.floor((seconds / 60));
    console.log(minute + ' min since last activity')
    if (minute >= 14) {
        // Check if current page is login page
        if (window.location.pathname === '/login') {
            console.log('Already on login page')
            // Do nothing if current page is login page
        } else {
            console.log('No activity from last 14 minutes... Logging Out')
            clearInterval(timeInterval)
            //code for logout or anything...
            logout()
        }
    }

}, 60000)

function App() {
    return (
        <div className="App">
            <Routes>
                <Route path={"/login"} element={<StaffLogin/>}/>
                <Route element={<PrivateRoutes/>}>
                    <Route element={<MainLayout/>}>
                        <Route path={"register"} element={<PatientRegistration/>}/>
                        <Route path={"/"} element={<Dashboard/>}/>
                        <Route path={"/checkin"} element={<Checkin/>}/>
                        <Route path={"calendar"} element={<Calendar/>}/>
                        <Route path={"consultation"} element={<Consultation/>}/>
                        <Route path={"lab"} element={<Laboratory/>}/>
                        <Route path={"medication"} element={<Pharmacy/>}/>
                        <Route path={"users"} element={<Users/>}/>
                    </Route>
                </Route>
            </Routes>
        </div>
    );
}

export default App;

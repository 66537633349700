import React from "react";
import {Card, CardContent, CardMedia, Grid, Typography} from "@mui/material";

function PatientCard({patientInfo}) {
    let card = <Grid item
                     style={{
                         justifyContent: "center",
                         alignItems: "center",
                     }}
    >
        <Card style={{
            borderRadius: 10,
            padding: 5,
            justifyContent: "center",
            alignItems: "center",
            minWidth: 450
        }}>
            <CardMedia>
                <img src={"/images/user/1.jpg"} alt={"user Img"}/>
            </CardMedia>
            <CardContent>
                <Typography variant={"h6"}>
                    Name: {patientInfo["fname"]} <span>{patientInfo["lname"]} </span>
                </Typography>
            </CardContent>
        </Card>
    </Grid>
    return card
}

export default PatientCard;
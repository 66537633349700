import React, {useState} from "react";
import {Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography} from "@mui/material";

function WaitList(props) {
    const [selectRow, setRow] = useState(null)
    let waitList = props.medicationlist


    function selectPatient(patient_id) {
        // console.log(patient_id)
        props.setPatientID(patient_id)
    }

    return (
        <Grid item
              style={{
                  backgroundColor: "white",
                  borderRadius: 15,
                  minWidth: 470,
                  padding: 10
              }}
        >
            <TableContainer>
                <Typography variant={"h5"}>
                    Waiting list
                </Typography>
                <Table>
                    <TableHead>
                        <TableCell>Patient</TableCell>
                        {/*<TableCell>Time</TableCell>*/}
                        {/*<TableCell>Medication</TableCell>*/}
                    </TableHead>
                    <TableBody>
                        {waitList?.map(({
                                            id,
                                            first_name, last_name
                                        }) => (
                            <TableRow
                                key={id}
                                id={id}
                                hover
                                onClick={() => {
                                    selectPatient(id)
                                    setRow(id)
                                    props.setPatientDetails({"fname": first_name, "lname": last_name})
                                    props.notesRefresh("")
                                }}
                                selected={selectRow === id}
                            >
                                <TableCell> {first_name} {last_name}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </Grid>
    )
}

export default WaitList
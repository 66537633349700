import React, {useEffect, useState} from "react"
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Button,
    Grid,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography
} from "@mui/material";
import API from "../../api/api"
import {ExpandMore} from "@mui/icons-material";
import PatientCard from "../../Components/PatientCard";
import DataTable from "./components/DataTable";
import TestResults from "./components/ResultsInput";


function DoctorSession() {
    const [patients, setPatients] = useState([])
    // const [tests, setTests] = useState("")
    const [lab, setLab] = useState([])
    const [procedure, setProcedure] = useState([])
    const [imaging, setImaging] = useState([])
    const [currentLab, setCurrentLab] = useState("")
    const [currentImage, setCurrentImage] = useState("")
    const [currentProcedure, setCurrentProcedure] = useState("")
    const [patientDetails, setPatientDetails] = useState({})
    const [labResults, setLabResults] = useState("")
    const [imageResults, setImageResults] = useState("")
    const [procedureResults, setProcedureResults] = useState("")
    const [selectRow, setRow] = useState(null)
    // const [testData, setTestData] = useState({});
    const [file, setFile] = useState(null);


    const handleSubmit = (event) => {
        event.preventDefault();

        const testData = new FormData();
        testData.append('file', file);
        testData.append('name', testData.name);
        testData.append('description', testData.description);

        // API.post('/api/upload', formData).then((response) => {
        //     console.log(response.data);
        // });
    };


    function selectPatient(patient_id, patient_details) {
        // This function will pull all tests data for a particular patient then populate other
        // places within the page
        let labs = []
        let imaging_objects = []
        let procedures = []
        API.put("laboratory/medicaltests/", {
            "patient": patient_id,
            "staff": localStorage.getItem("user_id"),
            "institution": localStorage.getItem('institution_id')
        }).then(
            (resp) => {
                for (let i = 0; i < resp.data.length; i++) {
                    if (resp.data[i]["test_type"] === "lab") {
                        labs.push(resp.data[i])
                    } else if (resp.data[i]["test_type"] === "imaging") {
                        imaging_objects.push(resp.data[i])
                    } else if (resp.data[i]["test_type"] === "procedure") {
                        procedures.push(resp.data[i])
                    }
                }
                setLab(labs)
                setImaging(imaging_objects)
                setProcedure(procedures)
            }
        )

        setPatientDetails(patient_details)

    }


    function updateTest(test, results, attachment) {
        // Receives the test `object` and the results that need to be updated.
        // It also sets the status of the medical test to `completed`
        let form_data = new FormData()
        form_data.append("test_id", test["id"])
        form_data.append("results", String(results))
        form_data.append("attachments", attachment ? attachment : "")
        form_data.append("status", "pending_review")
        form_data.append("staff", test["staff"])
        form_data.append("handler", localStorage.getItem("user_id"))
        form_data.append("institution", localStorage.getItem("institution_id")) // Data can be found in test,
        // but this is just to be safe and ensure the tests show where the results are coming from
        form_data.append("patient", test["patient"])

        if (results === "") {
            alert("Please enter results")
            return
        }
        API.patch("laboratory/medicaltests/", form_data)
            .then(resp => {
                if (resp.status === 206){
                    alert("Test results updated successfully")
                }
            })
        setLabResults("")
        setProcedureResults("")
        setImageResults("")
    }

    useEffect(() => {
            API.get("laboratory/medicaltests/get_patients_with_tests/", {
                headers: {
                    "Content-Type": "application/json",
                },
                params: {
                    "staff": localStorage.getItem("user_id"),
                    "institution": localStorage.getItem("institution_id")
                }
            })
                .then((resp) => {
                        setPatients(resp.data)
                        // console.log(resp.data)
                    }
                ).catch((e) => {
                // console.log(e)
            })
        }, []
    )

    let labTechView = (
        <Grid
            container
            rowSpacing={2}
        >
            <Grid item
                  container
                  spacing={2}
                  justifyContent={"space-between"}
            >
                <Grid item>
                    <PatientCard patientInfo={patientDetails}/>
                </Grid>
                <Grid item>
                    <Accordion
                        style={{
                            borderRadius: 15,
                            minWidth: 500
                        }}
                    >
                        <AccordionSummary expandIcon={<ExpandMore/>}>
                            <Typography>
                                Tests List
                            </Typography>
                        </AccordionSummary>
                        <hr/>
                        <AccordionDetails>
                            <TableContainer>
                                <Table>
                                    <TableHead>
                                        <TableCell>Patient</TableCell>
                                        {/*<TableCell>Tests</TableCell>*/}
                                    </TableHead>
                                    <TableBody>
                                        {patients.length > 0 ? patients.map(({first_name, last_name, id}) => (
                                            <TableRow
                                                key={id}
                                                sx={{'&:last-child td, &:last-child th': {border: 0}}}
                                                id={id}
                                                hover
                                                onClick={() => {
                                                    selectPatient(id,
                                                        {
                                                            "fname": first_name,
                                                            "lname": last_name
                                                        })
                                                    setRow(id)
                                                    // The three methods below will clear out any other tests in other
                                                    // test categories to allow for a fresh selection
                                                    setCurrentProcedure("")
                                                    setCurrentImage("")
                                                    setCurrentLab("")
                                                }}
                                                selected={selectRow === id}
                                            >
                                                <TableCell>{first_name} {last_name}</TableCell>
                                                {/*<TableCell>{id}</TableCell>*/}
                                            </TableRow>
                                        )) : <TableRow>
                                            <TableCell>
                                                <Typography variant={"h6"}>
                                                    No Pending Tests
                                                </Typography>
                                            </TableCell>
                                        </TableRow>}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </AccordionDetails>
                    </Accordion>
                </Grid>
            </Grid>

            <Grid item
                  container
                  spacing={2}
                  justifyContent={"space-between"}
            >
                <Grid item>
                    <Accordion
                        style={{
                            borderRadius: 15,
                            minWidth: 500
                        }}
                    >
                        <AccordionSummary expandIcon={<ExpandMore/>}>
                            <Typography>
                                Lab Tests
                            </Typography>
                        </AccordionSummary>
                        <hr/>
                        <AccordionDetails>
                            <DataTable
                                test_type={lab}
                                patientDetails={patientDetails}
                                setCurrentTest={setCurrentLab}
                            />
                        </AccordionDetails>
                    </Accordion>
                </Grid>
                <Grid item>
                    <Accordion
                        style={{
                            borderRadius: 15,
                            minWidth: 500
                        }}
                    >
                        <AccordionSummary expandIcon={<ExpandMore/>}>
                            <Typography>
                                Lab Test Results
                            </Typography>
                        </AccordionSummary>
                        <hr/>
                        <AccordionDetails>
                            <TestResults
                                currentTest={currentLab}
                                testResults={labResults}
                                setTestResults={setLabResults}
                                setFile={setFile}
                                updateTest={updateTest}
                                file={file}
                            />
                        </AccordionDetails>
                    </Accordion>
                </Grid>
            </Grid>
            <Grid item
                  container
                  spacing={2}
                  justifyContent={"space-between"}
            >
                <Grid item>
                    <Accordion
                        style={{
                            borderRadius: 15,
                            minWidth: 500
                        }}
                    >
                        <AccordionSummary expandIcon={<ExpandMore/>}>
                            <Typography>
                                Imaging
                            </Typography>
                        </AccordionSummary>
                        <hr/>
                        <AccordionDetails>
                            <DataTable
                                test_type={imaging}
                                patientDetails={patientDetails}
                                setCurrentTest={setCurrentImage}
                            />
                        </AccordionDetails>
                    </Accordion>
                </Grid>
                <Grid item>
                    <Accordion
                        style={{
                            borderRadius: 15,
                            minWidth: 500
                        }}
                    >
                        <AccordionSummary expandIcon={<ExpandMore/>}>
                            <Typography>
                                Imaging Results
                            </Typography>
                        </AccordionSummary>
                        <hr/>
                        <AccordionDetails>
                            <TestResults
                                currentTest={currentImage}
                                testResults={imageResults}
                                setTestResults={setImageResults}
                                setFile={setFile}
                                updateTest={updateTest}
                                file={file}
                            />
                        </AccordionDetails>
                    </Accordion>
                </Grid>
            </Grid>

            <Grid item
                  container
                  spacing={2}
                  justifyContent={"space-between"}
            >
                <Grid item>
                    <Accordion
                        style={{
                            borderRadius: 15,
                            minWidth: 500
                        }}
                    >
                        <AccordionSummary expandIcon={<ExpandMore/>}>
                            <Typography>
                                Procedures
                            </Typography>
                        </AccordionSummary>
                        <hr/>
                        <AccordionDetails>
                            <DataTable
                                test_type={procedure}
                                patientDetails={patientDetails}
                                setCurrentTest={setCurrentProcedure}
                            />
                        </AccordionDetails>
                    </Accordion>
                </Grid>
                <Grid item>
                    <Accordion
                        style={{
                            borderRadius: 15,
                            minWidth: 500
                        }}
                    >
                        <AccordionSummary expandIcon={<ExpandMore/>}>
                            <Typography>
                                Procedure Results
                            </Typography>
                        </AccordionSummary>
                        <hr/>
                        <AccordionDetails>
                            <TestResults
                                currentTest={currentProcedure}
                                testResults={procedureResults}
                                setTestResults={setProcedureResults}
                                setFile={setFile}
                                updateTest={updateTest}
                                file={file}
                            />
                        </AccordionDetails>
                    </Accordion>
                </Grid>
            </Grid>

            <Button
                style={{
                    borderRadius: 10,
                    backgroundColor: "#77c0a3",
                    color: "white",
                    marginRight: 5,
                    marginTop: 10
                }}
                // onclick, reload the page to clear out the patient
                onClick={() => {
                    window.location.reload()
                }}
            >
                <Typography variant={"body1"}>
                    Done with patient
                </Typography>
            </Button>
        </Grid>
    )

    return (
        labTechView
    )
}

export default DoctorSession;
import {Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography} from "@mui/material";
import React,{useState} from "react";

function TriageDataTable({appointment, selectNextPatient}) {

    return (
        <TableContainer component={Paper}>
            <Table sx={{minWidth: 600}} size="small" aria-label="Today's appointments">
                <TableHead>
                    <TableRow>
                        <TableCell>Patient</TableCell>
                        <TableCell>Time</TableCell>
                        <TableCell>Action</TableCell>

                    </TableRow>
                </TableHead>
                <TableBody>
                    {appointment.length > 0 ? appointment.map(({
                                                                   created_at,
                                                                   id,
                                                                   patient_id,
                                                                   patient_first_name,
                                                                   patient_last_name,
                                                               }) => (
                        <TableRow
                            key={id}
                            sx={{'&:last-child td, &:last-child th': {border: 0}}}
                            id={id}
                            hover
                            onClick={() => {
                                selectNextPatient(patient_id)
                            }}
                        >

                            <TableCell scope={"row"}>
                                {patient_first_name} {patient_last_name}
                            </TableCell>
                            <TableCell>{new Date(created_at).toLocaleTimeString([], {
                                hour: '2-digit', minute: '2-digit', hour12: true
                            })}</TableCell>
                            <TableCell>
                                <button onClick={() => {
                                    selectNextPatient(patient_id)
                                }}>Triage
                                </button>
                            </TableCell>
                        </TableRow>
                    )) : <TableCell>
                        <Typography variant={"h6"}>
                            No data to show
                        </Typography>
                    </TableCell>}
                </TableBody>
            </Table>
        </TableContainer>
    )
}

export default TriageDataTable;